<template>
  <v-container
    fill-height
    fluid
    style="flex-wrap: wrap;"
  >
    <div class="messages">
      <div
        id="app-messaging"
        class="mt-2"
        data-app>

        <div v-if="blank"/>
        <div
          v-else
          class="conversation-section h-100 mt-3">
          <div
            v-if="isEverythingReady"
            class="conversations h-100">
            <chat-list
              :default-user-image="defaultUserImage"
              ref="chatList"
              :new-conversation-image="newConversationImage"
              :all-users="allUsers"
              :channels="channels"
              :search="search"
              :chat-search-keyword="chatSearchKeyword"
              :user_id="userId"
              :unread_only="unreadOnly"
              @newConversation="newConversation"
              @openConversation="loadChannelMessages"
              @toggleUpdated="unreadToggleUpdated"
              @scroll="loadMoreChannels"
              @loadMoreUsers="loadMoreUsers"
              @searchUsers="searchUsers"
              @searchConversations="searchConversations"
            />
            <div
              v-if="activeView === 'blank'"
              class="blank-screen">
              <div class="blank-screen-content">
                <img
                  :src="blankScreenImage"
                  class="blank-screen-image">
                <p class="blank-screen-text">Please select a conversation to view messages</p>
              </div>
            </div>
            <chat-messages
              v-if="activeView === 'messages'"
              :user_id="userId"
              :profile-url="profileUrl"
              :default-user-image="defaultUserImage"
              :send-message-icon="sendMessageIcon"
              :send-message-icon-disabled="sendMessageIconDisabled"
              :message-read-icon="messageReadIcon"
              :message-unread-icon="messageUnreadIcon"
              :active-channel="activeChannel"
              :messages="messages"
              :messages-header="messagesHeader"
              @sendMessage="sendMessage"
              @loadMore="loadMoreMessages"
            />
          </div>
          <div
            v-else
            class="no-conversation">
            <div class="no-content-container">
              <p>Currently you have no messages</p>
              <button
                class="btn btn-primary"
                @click.stop="dialog=true">Start A Conversation</button>
            </div>
          </div>
        </div>

        <!-- Create New Conversation Modal -->
        <new-conversation
          :visible="dialog"
          :user_id="userId"
          :default-user-image="defaultUserImage"
          :all-users="allUsers"
          :search="search"
          @close="dialog=false"
          @newConversation="newConversation"
          @scroll="loadMoreUsers"
          @searchUsers="searchUsers"
        />

      </div>
    </div>
  </v-container>
</template>

<script>
    import * as SendBird from 'sendbird'

    import NewConversation from '../components/messaging/newConversationModal.vue'
    import ChatList from '../components/messaging/chatList.vue'
    import ChatMessages from '../components/messaging/chatMessages.vue'

    const send_bird_id = process.env.VUE_APP_SENDBIRD_APP_ID
    const sb = new SendBird({ appId: send_bird_id })
    const ChannelHandler = new sb.ChannelHandler()

    export default {
        name: 'Chat',
        components: { NewConversation, ChatList, ChatMessages },
        data: function () {
            return {
                isEverythingReady: true,
                dialog: false,
                blank: true,
                everythingReady: false,
                activeView: 'blank',
                defaultUserImage: '/icons/default_user_img.svg',
                blankScreenImage: '/icons/messages_ic.svg',
                newConversationImage: '/icons/message_ic.svg',
                sendMessageIcon: '/icons/send_icon.svg',
                sendMessageIconDisabled: '/icons/send_icon_disabled.svg',
                messageReadIcon: '/icons/message_read_ic.png',
                messageUnreadIcon: '/icons/message_unread_ic.png',
                userId: '0',
                fullName: '',
                username: '',
                profileUrl: '',
                channelListQuery: null,
                channels: [],
                activeChannel: null,
                messages: [],
                allUsers: [],
                messagesHeader: 'MOT User',
                connectedUsers: [],
                channelConnectedUser: null,
                showInput: false,
                unreadMessageCount: 0,
                skipUsers: 0,
                takeUsers: 20,
                search: '',
                reloading: false,
                unreadOnly: 1,
                chatSearchKeyword: '',
                nextToken: 'initial',
                skipConnectedUsers: 0,
                takeConnectedUsers: 20,
                prevMessageListQuery: null
            }
        },
        mounted: function () {
            if (window.location.href.indexOf('chat') > -1) {
                const self = this
                let unique_identifier = self.environmentPrefix + self.userId
                if (self.activeChannel) {
                    unique_identifier = self.activeChannel.name
                }
                ChannelHandler.onMessageReceived = function (channel, message) {
                    if (window.location.href.indexOf('chat') > -1) {
                        if (self.activeChannel && self.activeChannel.url === channel.url) {
                            self.messages.push(message)
                            self.scrollToBottom()
                            channel.markAsRead()
                        } else {
                            self.getChannels()
                        }
                        self.playAudio()
                    }
                }
                ChannelHandler.onTypingStatusUpdated = function (channel) {
                    if (window.location.href.indexOf('chat') > -1) {
                        let connectedUser = self.getChannelConnectedUser(channel)
                        if (self.activeChannel && self.activeChannel.url === channel.url && channel.isTyping() && connectedUser) {
                            $('#message-type').empty().append('<span>' + connectedUser.nickname + ' is typing</span><div class=typing_loader> </div>')
                        } else {
                            $('#message-type').empty()
                        }
                    }
                }
                ChannelHandler.onReadReceiptUpdated = function (groupChannel) {
                }

                // not implemented yet
                ChannelHandler.onMessageDeleted = function (channel, messageId) {
                    $('#incoming_msg_' + messageId).remove()
                }
                ChannelHandler.onMessageUpdated = function (channel, message) {
                    self.messages.map(p => p.messageId === message.messageId ? p.message = message.message : p.message)
                }

                sb.addChannelHandler(unique_identifier, ChannelHandler)
            }
        },
        created: function () {
            this.getConnectedUsers()
            this.getUserDataForChat()
        },
        methods: {
            getConnectedUsers () {
                const self = this
                let loader = self.$loading.show()
                self.relaoding = true
                self.everythingReady = false
                this.$http.get(this.url + 'connected_users', {
                    params: {
                        skip: self.skipUsers,
                        take: self.takeUsers,
                        skip_connected: self.takeConnectedUsers,
                        take_connected: self.skipConnectedUsers,
                        search: self.search,
                        unread_only: self.unreadOnly,
                        keyword: self.chatSearchKeyword,
                        next_token: self.nextToken
                    }
                }).then(function (response) {
                    loader.hide()
                    self.reloading = false

                    if (self.skipUsers === 0) {
                        self.allUsers = response.data.all_users
                    } else {
                        self.allUsers = self.allUsers.concat(response.data.all_users)
                    }
                    self.blank = false
                    self.everythingReady = true
                }).catch(function (error) {
                    self.reloading = false
                    loader.hide()
                })
            },
            getUserDataForChat: function () {
                const self = this
                let loader = this.$loading.show()
                this.$http.get(this.url + 'chat/user_data', {
                    params: {
                        skip: this.skip,
                        take: this.take,
                        keyword: this.keyword,
                        type: 'therapist'
                    }
                }).then(function (response) {
                    loader.hide()
                    self.userId = String(response.data.user.id)
                    self.fullName = response.data.user.name
                    self.username = response.data.user.username
                    self.profileUrl = response.data.user.profile_picture
                    self.initializeSendBird()
                }).catch(function (error) {
                    loader.hide()
                    self.$toastr('error', error.response.data.message, 'Error')
                })
            },
            initializeSendBird: function () {
                const self = this
                // connect user to sendbird
                const sbAccessToken = localStorage.getItem('sb_access_token')
                sb.connect(self.environmentPrefix + self.userId, sbAccessToken, function (user, error) {
                    if (error) {
                        return
                    }

                    // update user data to sync sendbird with local db
                    sb.updateCurrentUserInfo(self.fullName, self.profileUrl, function (response, error1) {
                        if (error1) {
                            return
                        }

                        // update user metadata to sync with local db
                        let data = {
                            'username': self.username
                        }
                        let currentUser = sb.currentUser
                        currentUser.updateMetaData(data, true, function (metadata, err) {
                            if (err) {

                            }
                        })

                        // getting all conversations
                        self.getChannels()
                    })
                })
            },
            getChannels: function () {
                const self = this
                let loader = self.$loading.show()
                let channels = []
                let channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery()
                // making channels list query
                channelListQuery.includeEmpty = false
                if (this.unreadOnly == 1) {
                    channelListQuery.unreadChannelFilter = 'unread_message'
                }
                if (this.chatSearchKeyword !== '') {
                    channelListQuery.nicknameContainsFilter = this.chatSearchKeyword
                }
                channelListQuery.limit = 100 // pagination limit could be set up to 100
                self.channelListQuery = channelListQuery
                // getting channels
                self.getChannelsFromSendBird(channelListQuery, channels, loader)
            },
            getChannelsFromSendBird: function (channelListQuery, channels, loader) {
                let self = this
                if (channelListQuery.hasNext) { // load more if have any
                    channelListQuery.next(function (channelList, error) {
                        if (error) {
                            return
                        }
                        channels = channels.concat(channelList)
                        self.filterAndShowChannels(channels, loader)
                    })
                } else {
                    self.blank = false
                    self.everythingReady = true
                    loader.hide()
                }
            },
            filterAndShowChannels (channels, loader) {
                const self = this
                channels = channels.filter((channel) => {
                 return channel.lastMessage && channel.lastMessage.message !== "Welcome to My Online Therapy. Please get in touch if you have any questions. We're always thinking of new ways to improve our service and if you have feedback or ideas, we'd love to hear from you."
                })
                self.channels = channels
                if (channels.length < 30) {
                  self.getChannelsFromSendBird(self.channelListQuery, self.channels, loader)
                } else {
                  self.blank = false
                  self.everythingReady = true
                  loader.hide()
                }
            },
            loadMoreChannels () {
                const self = this
                let container = document.getElementById('chat_list_container')
                if ((container.clientHeight + container.scrollTop) >= container.scrollHeight) {
                    let loader = self.$loading.show()
                    self.getChannelsFromSendBird(self.channelListQuery, self.channels, loader)
                }
            },
            openChat: function (user_id) {
                const self = this
                let user_index = self.connectedUsers.findIndex(x => x.id === user_id)
                if (user_index > -1) {
                    let connectedUser = self.connectedUsers[user_index]
                    if (connectedUser.profile_picture === '') {
                        connectedUser.profile_picture = self.defaultUserImage
                    }
                    self.channelConnectedUser = connectedUser
                    self.createChannel()
                }
            },
            unreadToggleUpdated (value) {
                this.activeView = 'blank'
                this.unreadOnly = value
                this.activeChannel = null
                this.channels = []
                this.messages = []
                this.getChannels()
            },
            searchConversations (keyword) {
                this.chatSearchKeyword = keyword
                this.channels = []
                this.messages = []
                this.getChannels()
            },
            loadChannelMessages: function (channel) {
                const self = this
                self.activeChannel = channel
                self.activeView = 'messages'
                self.messagesHeader = 'MOT User'
                let loader = self.$loading.show()

                // get connected user name
                let members = channel.members
                $.each(members, function (index, member) {
                    let userId = member.userId
                    userId = userId.split('-')
                    userId = userId[1]
                    if (userId !== self.userId && member.nickname !== '') {
                        self.messagesHeader = member.nickname
                    }
                })
                let prevMessageListQuery = channel.createPreviousMessageListQuery()
                prevMessageListQuery.limit = 30
                prevMessageListQuery.reverse = false
                self.prevMessageListQuery = prevMessageListQuery
                prevMessageListQuery.load(function (messages, error) {
                    if (error) {
                        return
                    }
                    self.messages = messages
                    self.showInput = true
                    self.scrollToBottom()
                    self.activeChannel.markAsRead()
                    sb.getTotalUnreadMessageCount(function (msg) {
                        sb.getTotalUnreadMessageCount(function (messagesCount) {
                            self.unreadMessageCount = messagesCount
                            if (self.unreadMessageCount > 0) {
                                $('.slickdot-nav-unread-message').removeClass('d-none')
                            } else {
                                $('.slickdot-nav-unread-message').addClass('d-none')
                            }
                        })
                    })
                    loader.hide()
                })
            },
            loadMoreMessages: function () {
                let self = this
                let container = document.getElementById('messages_container')
                const old_height = container.scrollHeight

                if (self.prevMessageListQuery) {
                    let prevMessageListQuery = self.prevMessageListQuery
                    if (prevMessageListQuery.hasMore) {
                        prevMessageListQuery.load(function (messages, error) {
                            if (error) {
                                return
                            }
                            let allMessages = messages
                            allMessages = allMessages.concat(self.messages)
                            self.messages = allMessages
                            self.showInput = true
                            self.scrollToBottom()
                            self.activeChannel.markAsRead()
                            setTimeout(function () {
                                const new_height = container.scrollHeight
                                container.scrollTop = (new_height - old_height)
                            }, 100)
                            sb.getTotalUnreadMessageCount(function (msg) {
                                sb.getTotalUnreadMessageCount(function (messagesCount) {
                                    self.unreadMessageCount = messagesCount
                                    if (self.unreadMessageCount > 0) {
                                        $('.slickdot-nav-unread-message').removeClass('d-none')
                                    } else {
                                        $('.slickdot-nav-unread-message').addClass('d-none')
                                    }
                                })
                            })
                        })
                    }
                }
            },
            scrollToBottom: function () {
                this.scrollDownToBottom('messages_container', 'messages_history')
            },
            sendMessage: function (message) {
                if (message === '') {
                    return false
                }
                const self = this
                let channel = self.activeChannel

                channel.sendUserMessage(message, function (message1, error) {
                    if (error) {
                        return
                    }

                    self.messages.push(message1)
                    self.scrollToBottom()
                })
            },
            newConversation (data) {
                this.selectUser(data)
                this.createChannel()
            },
            selectUser: function (user) {
                this.channelConnectedUser = user
            },
            createChannel: function () {
                const self = this
                let connected_user = this.channelConnectedUser
                let userIds = []
                userIds.push(self.environmentPrefix + self.userId, self.environmentPrefix + connected_user.id)

                let loader = self.$loading.show()
                self.activeView = 'blank'
                self.isEverythingReady = false

                // connect self with send-bird
                const sbAccessToken = localStorage.getItem('sb_access_token')
                sb.connect(self.environmentPrefix + self.userId, sbAccessToken, function (user, error) {
                    if (error) {
                        return
                    }

                    // create send-bird chat channel with connected user
                    sb.GroupChannel.createChannelWithUserIds(userIds, true, connected_user.channel_name, '', '', '', function (groupChannel, err) {
                        if (err) {
                            return
                        }

                        let channel_data = groupChannel
                        let channel_index = self.channels.findIndex(x => x.url === groupChannel.url)
                        if (channel_index > -1) {
                            self.channels.splice(channel_index, 1)
                        }
                        self.channels.unshift(channel_data)
                        self.isEverythingReady = true
                        self.loadChannelMessages(groupChannel)
                    })

                    if (self.$refs.chatList) {
                        self.$refs.chatList.hideModal()
                    } else {
                        self.dialog = false
                    }
                    self.dialog = false
                    loader.hide()
                })
            },
            loadMoreUsers () {
                const self = this
                let container = document.getElementById('recipients_container')
                if ((container.clientHeight + container.scrollTop) >= container.scrollHeight) {
                    self.skipUsers = self.skipUsers + self.takeUsers
                    self.getConnectedUsers()
                }
            },
            searchUsers (search) {
                this.skip = 0
                this.search = search
                this.allUsers = []
                this.getConnectedUsers()
            },
            hideModal () {
                this.$refs.myModalRef.hide()
            },
            playAudio () {
                // playAudio();
            },
            getChannelConnectedUser (channel) {
                let members = channel.members
                let channelUser = null
                let filteredMembers = members.filter(
                    member => member.userId !== self.environmentPrefix + self.user_id
                )
                channelUser = filteredMembers.length > 0
                    ? filteredMembers[0]
                    : {
                        ...members,
                        nickname: 'Deleted User',
                        profileUrl: ''
                    }
                return channelUser
            }
        }
    }
</script>
